var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 font-size-18"},[_vm._v(_vm._s(_vm.t("Tickets")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mx-auto"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[(_vm.ticket.errored)?_c('div',[_vm._v(" "+_vm._s(_vm.t( "We apologize, we are unable to retrieve information at this time. Please try again later." ))+" ")]):(_vm.ticket.empty)?_c('div',[_vm._v(_vm._s(_vm.t("No records found.")))]):(!_vm.ticket.loading && _vm.ticket.interactions.length == 0)?_c('div',[_vm._v(" Ticket not found. ")]):_c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"invoice-title"},[_c('h4',{staticClass:"font-size-15 p-1"},[(!_vm.ticket.loading)?_c('div',[_vm._v(" Ticket # "+_vm._s(this.ticket.id)+" ")]):_vm._e()])]),_c('hr'),(_vm.ticket.loading)?_c('vcl-list'):_vm._l((_vm.ticket.interactions),function(row,index){return _c('div',{key:index},[(row.type === 'affiliate')?_c('div',[_c('div',{staticClass:"d-flex pr-3 pl-3"},[_c('div',{staticClass:"col-lg-1 d-none d-md-block"}),_c('div',{staticClass:"col-lg-11 mr-auto p-0"},[_c('div',{staticClass:"mb-2 text-right pr-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(row.name))]),(_vm.account.user.avatar)?_c('img',{staticClass:"rounded-circle header-profile-user ml-2",attrs:{"src":_vm.account.user.avatar,"alt":""}}):_c('img',{staticClass:"rounded-circle header-profile-user ml-2",attrs:{"src":'https://ui-avatars.com/api/?background=230270&color=fff&name=' +
                          _vm.account.user.name,"alt":""}}),_c('br')]),_c('div',{staticClass:"card bg-soft-success m-0 p-3",domProps:{"innerHTML":_vm._s(row.description)}}),_c('div',{staticClass:"text-right font-size-11 text-muted p-1"},[_vm._v(" "+_vm._s(row.date)+" "),_c('i',{staticClass:"mdi mdi-check-all font-size-14 align-middle"})])])])]):_c('div',[_c('div',{staticClass:"d-flex pr-3 pl-3"},[_c('div',{staticClass:"col-lg-11 mr-auto p-0"},[_c('div',{staticClass:"mb-2 pl-1"},[_c('img',{staticClass:"rounded-circle header-profile-user mr-2",attrs:{"src":require("@/assets/images/logo-icon.png"),"alt":""}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(row.name))]),_c('br')]),_c('div',{staticClass:"card bg-soft-warning m-0 p-3",domProps:{"innerHTML":_vm._s(row.description)}}),_c('div',{staticClass:"font-size-11 text-muted p-1"},[_vm._v(" "+_vm._s(row.date)+" ")])]),_c('div',{staticClass:"col-lg-1 d-none d-md-block"})])]),_c('hr')])}),(!_vm.ticket.loading)?_c('b-form',{staticClass:"p-3",on:{"submit":function($event){$event.preventDefault();return _vm.ticketSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"description","label":_vm.t('Enter your message'),"label-for":"value"}},[_c('b-form-textarea',{class:{ 'is-invalid': _vm.$v.form.description.$error },attrs:{"id":"description","rows":"5","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.$v.form.description.$error)?_c('div',{staticClass:"invalid-feedback"},[(
                      !_vm.$v.form.description.required ||
                      !_vm.$v.form.description.minLength
                    )?_c('span',[_vm._v("Message is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"disabled":_vm.form.loading == true ||
                    this.form.description.length < 2 ||
                    _vm.$v.form.$error,"type":"submit","variant":"default"}},[_vm._v(" "+_vm._s(_vm.t("Send"))+" "),(_vm.form.loading)?_c('b-spinner',{staticClass:"ml-2 align-middle",attrs:{"small":"","variant":"white","role":"status"}}):_vm._e()],1)],1)],1):_vm._e()],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }